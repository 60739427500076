import { graphql, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../components/layout"
import BackgroundImage from "gatsby-background-image-es5"
import Slanted, { NormalHeading } from "../components/layout/slanted"
import ConvertKitForm from "convertkit-react"
import SEO from "../components/SEO"
import downsize from "downsize"
import Youtube from "../components/youtube"
import { PostList } from "../components/postList"
const ReadMoreLink = ({
  label = "Läs mer",
  to,
  size = "lg",
  white = false,
}) => {
  return (
    <div className="flex flex-row justify-end">
      <Link
        to={to}
        className={`w-full md:w-1/2 underline  text-${size} mt-8 ${
          white ? "text-white hover:text-white" : ""
        } text-right`}
      >
        {label} &rarr;
      </Link>
    </div>
  )
}

const Main = ({
  data: { hero, programs, why, how, latest, fileName, stodOss },
}) => {
  // Set ImageData.

  return (
    <Layout>
      <SEO
        title="Ta tillbaka kyrkan!"
        image={hero.featuredImage.node.localFile.childImageSharp.fixed}
        description={hero.content}
      ></SEO>
      <Slanted
        imageData={hero.featuredImage.node.localFile.childImageSharp.fluid}
        style={{ height: "100vh" }}
      >
        <div className="flex flex-col justify-between h-full">
          <div>
            <h1 className="text-white text-5xl sm:text-8xl lg:text-9xl text-shadow-lg text-right">
              {hero.title}
            </h1>
            <div className="text-white text-right text-shadow-contrast">
              <div dangerouslySetInnerHTML={{ __html: hero.content }} />
            </div>
          </div>
        </div>
      </Slanted>

      <Slanted>
        <Youtube
          ytUrl={"https://www.youtube.com/watch?v=puD5amDW15k"}
        ></Youtube>
        <h1 className="text-3xl sm:text-6xl mt-10 md:mt-24">
          {programs.title}
        </h1>
        <div className="flex flex-col md:flex-row md:justify-around md:content-center my-4  ">
          <div className="flex flex-row justify-center ">
            <Link
              to="/manifest"
              className="secondary-button w-full md:w-auto text-center"
            >
              Läs vårt valmanifest här!
            </Link>
          </div>
          <div className="flex flex-col items-center md:items-start text-gray-700 mt-4 md:mt-0 text-sm">
            Hinner du bara läsa det viktigaste?{" "}
            <Link to="/punkter"> Läs vårt program i punktform!</Link>
          </div>
        </div>
        <div>
          <div
            dangerouslySetInnerHTML={{ __html: programs.pageFields.summary }}
          ></div>
          <ReadMoreLink
            to="/listor"
            label="Se våra kandidater här!"
          ></ReadMoreLink>
        </div>
      </Slanted>

      <Slanted
        imageData={why.featuredImage.node.localFile.childImageSharp.fluid}
      >
        <div>
          <NormalHeading white={true} align="right">
            {why.title}
          </NormalHeading>
          <div className="text-white text-shadow-contrast ">
            <div
              dangerouslySetInnerHTML={{ __html: why.pageFields.summary }}
            ></div>
            <ReadMoreLink
              to="/varfor-rosta"
              label="Läs mer om varför det är viktigt att du röstar i kyrkovalet!"
              size="xl"
              white
            ></ReadMoreLink>
          </div>
        </div>
      </Slanted>
      <Slanted>
        <div>
          <NormalHeading>{how.title}</NormalHeading>
          <div>
            <div
              dangerouslySetInnerHTML={{ __html: how.pageFields.summary }}
            ></div>
            <ReadMoreLink
              to="/sa-rostar-du"
              label="Vi guidar dig genom kyrkovalet!"
              size="xl"
            ></ReadMoreLink>
          </div>
        </div>
      </Slanted>
      <Slanted
        imageData={stodOss.featuredImage.node.localFile.childImageSharp.fluid}
      >
        <div>
          <NormalHeading>{stodOss.title}</NormalHeading>
          <div>
            <div dangerouslySetInnerHTML={{ __html: stodOss.content }}></div>
            <div className="flex flex-col items-center">
              <a
                className="secondary-button  w-full md:w-auto text-center mt-10"
                href="https://alternativforsverige.se/bli-medlem"
              >
                Bli medlem idag!
              </a>
              <a
                className="mt-4 text-lg"
                href="https://alternativforsverige.se/donera"
              >
                Skänk en donation.
              </a>
            </div>
          </div>
        </div>
      </Slanted>
      <Slanted
        innerClass="bg-green"
        className="bg-green"
        imageData={fileName.childImageSharp.fluid}
        white
      >
        <NormalHeading align="center" white>
          Missa inga nyheter!
        </NormalHeading>
        <div className="flex flex-col w-full mt-6 justify-around ">
          <div className="mx-auto text-white">
            <p>
              Håll dig uppdaterad om vårt kyrkovalsarbete genom att skriva upp
              dig på vår e-postlista!
            </p>
            <ConvertKitForm
              formId={2112196}
              template="clare"
              namePlaceholder="Namn"
              emailPlaceholder="e-post"
              submitText="Jag vill ha uppdateringar!"
              stack={false}
            ></ConvertKitForm>
          </div>
        </div>
      </Slanted>
      <Slanted innerClass="bg-white">
        <NormalHeading>Senaste nytt!</NormalHeading>
        <PostList posts={latest.edges} />

        {latest.totalCount > 5 ? (
          <ReadMoreLink
            to="/senaste/1"
            label="Fler nyheter"
            size="xl"
          ></ReadMoreLink>
        ) : (
          ""
        )}
      </Slanted>
    </Layout>
  )
}

export default Main

export const data = graphql`
  query HomeQuery {
    hero: wpPage(slug: { eq: "hero" }) {
      id
      title
      content

      featuredImage {
        node {
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
              fixed(quality: 90, width: 1200) {
                src
              }
            }
          }
        }
      }
    }
    stodOss: wpPage(slug: { eq: "stod-oss" }) {
      id
      title
      content

      featuredImage {
        node {
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
              fixed(quality: 90, width: 1200) {
                src
              }
            }
          }
        }
      }
    }
    programs: wpPage(slug: { eq: "program" }) {
      id
      title
      content
      pageFields {
        summary
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    how: wpPage(slug: { eq: "sa-rostar-du" }) {
      id
      title
      content
      pageFields {
        summary
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    why: wpPage(slug: { eq: "varfor-rosta-i-kyrkovalet" }) {
      id
      title
      content
      pageFields {
        summary
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    latest: allWpPost(sort: { fields: date, order: DESC }, limit: 3) {
      totalCount
      edges {
        node {
          id
          title
          uri
          date
          content
          blocks {
            name
            saveContent
          }
          postFieldGroup {
            author {
              ... on WpAuthor {
                id
                authorFields {
                  name
                }
              }
            }
          }
        }
      }
    }
    fileName: file(relativePath: { eq: "overlay.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

//sa-rostar-du
//varfor-rosta-i-kyrkovalet
