import React, { useState, useRef, useEffect } from "react"
import YouTubePlayer from "react-youtube"
import ReactPlayer from "react-player/youtube"

const Youtube = ({ ytUrl }) => {
  const ref = useRef()
  // Call the hook passing in ref and root margin
  // In this case it would only be considered onScreen if more ...
  // ... than 300px of element is visible.

  const [state, setState] = useState({
    loaded: false,
    playing: false,
    hasEnded: false,
  })

  const onReady = ({ target }) => {
    setState({ ...state, loaded: true })
  }

  const onEnded = () => {
    setState({ ...state, hasEnded: true })
  }

  useEffect(() => {
    let observer = null

    observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        if (entry.isIntersecting && !state.hasEnded) {
          setState({ ...state, playing: true })
        } else {
          setState({ ...state, playing: false })
        }
      },
      {
        rootMargin: "-100px",
      }
    )
    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => {
      try {
        if (ref.current) observer.unobserve(ref.current)
      } catch (error) {}
    }
  }, [state.loaded])

  return (
    <div ref={ref} className="aspect-w-16 aspect-h-9 w-full h-full">
      <ReactPlayer
        onEnded={onEnded}
        onReady={onReady}
        url={ytUrl}
        playing={state.playing}
        controls={true}
        width="100%"
        height="100%"
      />
    </div>
  )
}

export default Youtube
